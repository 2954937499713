<template>
  <router-view/>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

  :root{
    --color-pp--: #08672F;
    --color-white--: white;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /**Framework */
  .container{
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 4%;
  }

  .flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  /**Framework */
</style>
