<template>
    <header class="desktop">
        <div class="container">
            <div class="flex">
                <div class="logo">
                    <img src="../assets/logo.svg" alt="">
                </div><!--logo-->
                <ul>
                    <li>Nos conheça!</li>
                    <li>Alugue Particula</li>
                    <li>Aluge para motoristas de app</li>
                </ul>
                <div class="btn-action-contato" @click="clickNumero">
                    <div class="icon">
                        <img src="../assets/icon-fone.svg" alt="">
                    </div><!--icon-->
                    <h2>Ajuda ao Cliente <span>(79) 99183-4834</span></h2>
                </div><!--btn-action-contato-->
            </div><!--flex-->
        </div><!--container-->
    </header>

    <header class="mobile">
                <div class="flex">
                    <div class="logo">
                            <img src="../assets/logo.svg" alt="">
                        </div><!--logo-->
                    <div class="menu">
                        <div class="icon" @click="mostrarMenu">
                            <img src="../assets/icon-menu.svg" alt="" v-if="!menuIcon">
                            <img src="../assets/icon-menu-x.svg" v-else>
                        </div><!--icon-->
                        <div class="links" ref="link" v-if="menu">
                            <ul>
                            <li><a href="\">Nos conheça!</a></li>
                            <li><a href="\">Alugue Particula</a></li>
                            <li><a href="\">Aluge para motoristas de app</a></li>
                            <div class="btn-action-contato" @click="clickNumero">
                                <div class="icon">
                                    <img src="../assets/icon-fone.svg" alt="">
                                </div><!--icon-->
                                <h2>Ajuda ao Cliente <span>(79) 99183-4834</span></h2>
                            </div><!--btn-action-contato-->
                        </ul>
                        </div><!--links-->
                    </div><!--menu-->
                </div><!--flex-->
        </header><!--menu-mobile-->
</template>

<script>
export default {
data(){
        return {
            menu: false,
            menuIcon: false
        }
    },
    methods: {
        mostrarMenu(){
            const elemento = this.$refs.link;
            if(this.menu == false){
                if(elemento) elemento.classList.remove('stop');
                this.menuIcon = true;
                this.menu = true;
                return;
            }

            this.menuIcon = false;
            elemento.classList.add('stop');
            setTimeout(()=>{
                this.menu = false;
            }, 510)
        },
        clickNumero(){
            window.location.href = "https://api.whatsapp.com/send/?phone=5579991834834&text=Quero%20locar!"
        }
    }
}
</script>

<style scoped>
 header.mobile{
    display: none;
 }

 header.desktop{
    z-index: 100000;
    width: 95%;
    margin: 0 auto;
    background: var(--color-pp--);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 40px 0px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
 }

  header.desktop div.logo{
    width: 20%;
    max-width: 120px;
  }

  header.desktop div.logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  header.desktop ul{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    list-style-type: none;
  }

  header.desktop ul li{
    color: var(--color-white--);
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding: 0px 8px;
    transition: .5s;
    margin-right: 20px;
    text-align: center;
    cursor: pointer;
  }

  header.desktop ul li:hover{
    font-weight: 900;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-white--);
  }

  .btn-action-contato{
    padding: 10px 20px;
    border-radius: 8px;
    background: var(--color-white--);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  .btn-action-contato .icon{
    width: 20px;
    height: auto;
    margin-right: 8px;
  }

  .btn-action-contato .icon img{
    object-fit: contain;
  }

  .btn-action-contato h2{
    color: var(--color-pp--);
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  .btn-action-contato h2 span{
    display: block;
    font-weight: 900;
  }

  @media screen and (max-width: 1025px) {
    header.desktop ul li{
        font-size: 14px;
    }

    header.desktop{
    padding: 30px 0px;
 }
  }

  @media screen and (max-width: 960px){
    header.desktop ul li{
        font-size: 12px;
    }
    header.desktop div.logo{
    width: 20%;
    max-width: 100px;
  }
    .btn-action-contato{
        padding: 10px 10px;
    }
    .btn-action-contato h2{
    font-size: 12px;
  }
  }

  @media screen and (max-width: 850px){
        header.desktop{
            display: none;
        }

        header.mobile{
            z-index: 100000;
            width: 96%;
            margin: 0 auto;
            background: var(--color-pp--);
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 20px 20px;
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            display: block;
        }

        header.mobile .logo{
            width: 30%;
            max-width: 128px;
            height: auto;
        }

        header.mobile .logo img{
            width: 100%;
            height: auto;
            object-fit: contain;
        }

        div.menu{
            width: 30px;
            display: inline-block;
        }

        header div.menu ul{
            display:block;
            width: 100%;
            text-align: left;
            list-style-type: none;
        }
        div.menu div.btn{
            display: block;
        }

        div.menu .btn-action-contato{
            width: 100%;
    padding: 10px 20px;
    border-radius: 8px;
    background: var(--color-white--);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  div.menu .btn-action-contato .icon{
    width: 20px;
    height: auto;
    margin-right: 8px;
  }

  div.menu .btn-action-contato .icon img{
    object-fit: contain;
  }

  div.menu .btn-action-contato h2{
    color: var(--color-pp--);
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  div.menu .btn-action-contato h2 span{
    display: block;
    font-weight: 900;
  }

        div.menu .icon{
            width: 100%;
            height: 35px;
            cursor: pointer;
        }

        div.menu .icon img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        div.menu{
            width: 22px;
            display: inline-block;
            position: relative;
        }

        header.mobile div.links{
            position: absolute;
            top: 40px;
            left: -310px;
            border-radius: 10px;
            width: 80%;
            min-width: 340px;
            background: var(--color-pp--);
            border: 1px solid var(--color-white--);
            padding: 15px 15px;
            animation-name: mostrarLinks;
            animation-duration: 1s;
            animation-iteration-count: 1;
            opacity: 1;
            transition: .5s;
        }

        header.mobile div.links.stop{
            opacity: 0 !important;
        }

        @keyframes mostrarLinks {
            from{
                opacity: 0;
                top: 20px;
            }to{
                opacity: 1;
                top: 40px;
            }
        }

        header.mobile div.links ul li{
            padding-bottom: 15px;
            border-bottom: 1px solid var(--color-white--);
            margin-bottom: 15px;
        }

        header.mobile div.links ul li a{
            font-size: 14px;
            color: var(--color-white--);
            font-family: 'Inter', sans-serif;
            font-weight: 900;
            text-decoration: none;
        }
  }
</style>